import React from "react"
import Layout from "../components/layouts"
import { Hero, Breadcrumb, CaseStudyItem, SeoBlock } from "../components/slices"
import { Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const CaseStudyListing = ({ pageContext, uri }) => {
  const { title, subtext, seo, childPages, slug } = pageContext
  const breadcrumbData = [
    { title: "Home", _meta: "/" },
    { title, _meta: `/${slug}` },
  ]
  return (
    <Layout>
      <SeoBlock data={seo} />
      <Hero data={pageContext} className="-compact" />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
      </div>
      <div className="content">
        <div className="[ container -pinched ]">{renderRichText(subtext)}</div>
        <div className="container">
          <div className="case-study-holder">
            {childPages.map((page, index) => {
              return (
                <CaseStudyItem
                  key={`CaseStudyItem-${index}`}
                  data={page}
                  parent={slug}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="call-to-action">
        <div className="container">
          <div className="call-to-action__inner">
            <p className="[ title h3 ]">
              Kickstart your project with Solicom today
            </p>
            <Link className="button" to="/contact">
              Contact us
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudyListing
